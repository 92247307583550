import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import LoadingOverlay from 'react-loading-overlay'
import { Button, Typography, Grid, Box, Hidden, useTheme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useForm, FormProvider } from "react-hook-form";
import ReCAPTCHA from "react-google-recaptcha";

import logoMet from '../../assets/MetLifeLogo.svg'
import ImageLogin from '../../assets/FodoMetlifeFull.jpg'
//import ImageGif from '../../assets/LoaderGif.gif'
import { authActions, notificationActions, validationActions, presentationCredentialActions } from '../../store/actions'
import { UseImage, Alert, InputField } from '../../components/'
import { obtenerDireccionIP } from '../../utils'
import QRCode from 'qrcode.react';
import { HubConnectionBuilder } from '@microsoft/signalr';
import imageLoad from '../../assets/Loading_Overlay.gif'

import { getHash } from '../../utils'
import packageJson from "../../../package.json";
import { SelectLanguage } from '../../components/SelectLanguage'
import { useTranslation } from 'react-i18next';
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { msalConfig } from "../../msalConfig";
import LoginMultiRol from './LoginMultiRol'
import { InteractionRequiredAuthError } from '@azure/msal-browser'

const useStyles = makeStyles((theme) => ({
  //Estílos solamente del componente "LoginComponent"
  containerImagen: {
    height: "100vh",
  },
  imagenIlustrativa: {
    height: "100%",
    width: "100%",
    backgroundRepeat: 'none',
    backgroundSize: '100%',
    objectFit: 'cover',
    transform: 'scaleX(-1)'
  },
  imagenLogin: {
    // height: "10vh",
    // width: "100%",
    /*   backgroundRepeat: 'none',
      backgroundSize: '100%',
      objectFit: 'cover', */
    margin: '10px',
    backgroundRepeat: 'none',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    // width: '100%', /* Opcional: Asegura que el ancho sea del 100% del contenedor */
    // height: '100%',
  },
  loginBox: {
    //top: '18%',
    top: 0,
    bottom: 0,
    margin: "auto 0",
    width: '35%',
    height: '80% !important',
    right: '2%',
    overflow: 'auto',
    position: 'absolute',
    background: '#FFFFFF',
    boxShadow: '0px 4px 100px 0px rgba(138, 138, 138, 0.25)',
    borderRadius: 63,
    padding: '25px 35px',
    '@media only screen and (max-width: 600px)': {
      width: '100%',
      height: '100%',
      position: 'relative',
    },
    '@media only screen and (min-height: 900px)': {
      //height: '55%',
    },
    '@media only screen and (max-height: 899px)': {
      //top: '8%',
      //height: '85%',
    },
    '@media only screen and (min-height: 2501px)': {
      //top: '10%',
      //height: '60%',
    },
  },
  formInput: {
    width: "100%",
    padding: 1,
  },
  boxReponsive: {
    width: "50%",
    [theme.breakpoints.down("sm")]: {
      width: "95%",
    },
  },
  containerForm: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      width: "95%",
    },
  },
  form: {
    display: "flex",
    justifyContent: 'space-evenly',
    flexDirection: "column",
    width: "100%",
    height: "100%"
  },
  formAuth: {
    display: "flex",
    //justifyContent: 'space-evenly',
    flexDirection: "column",
    width: "100%",
    height: "100%",
    alignItems: "center",
    justifyContent: 'center'
  },
  loader: {
    position: 'absolute !important',
    top: '0 !important',
    bottom: '0 !important',
    left: '0 !important',
    right: '0 !important',
    height: '100vh !important',
    width: '100% !important',
  },
  buttonPrimary: {
    border: '1px solid rgba(0, 97, 160, 1) !important',
    background: '#ffffff !important',
    color: ' rgba(0, 97, 160, 1) !important'
  }
}));

export const iniciarConexionSignalR = () => {
  const authenticatorUrl = process.env.REACT_APP_URL_VERIFY_CREDENTIALS;

  const connection = new HubConnectionBuilder()
    .withUrl(authenticatorUrl)
    .build();

  connection.start().catch(error => console.error(error));

  return connection;
};

export default function LoginComponent(props) {
  const theme = useTheme()

  const formMethods = useForm({ mode: "onChange" });
  const { control, handleSubmit, register, formState } = useForm({ mode: "onBlur" });
  const [seconds, setSeconds] = useState(180); // 3 minutos en segundos
  const [isActive, setIsActive] = useState(false);

  const [user, setUser] = useState('')
  const [pass, setPass] = useState('')
  const [lat, setLat] = useState("12222");
  const [lon, setLon] = useState("22222");
  const [sendCAPTCHA, setSendCAPTCHA] = useState(false)

  const [loadView, setLoadView] = useState(false)
  const [showPass, setShowPass] = useState(false)
  const [formChange, setFormChange] = useState(false)
  const [requestCaptcha, setRequestCaptcha] = useState(false)
  const [captchaSucess, setCaptchaSucess] = useState(false)
  const [ipCurrent, setIpCurrent] = useState("")
  const [tokenSR, setIToken] = useState("")
  const [qrValueVC, setQRValue] = useState("Credenciales Verificadas")
  const [isVisible, setMostrarElemento] = useState(false);
  const [isVisibleQR, setShowQR] = useState(true);
  const [idRef, setIdRef] = useState('')
  const [multirol, setMultirol] = useState(false);

  const { i18n, t } =  useTranslation();

  const classes = useStyles(theme)

  // const fieldStyles = null //mainStyles.useStylesField()
  const navigation = useNavigate()
  const { id } = useParams()


  //Redux hooks
  const auth = useSelector(state => state.Authentication)
  const urlVC = useSelector(state => state.PresentationVCRemote);
  // const file = useSelector(state => state.FileInfo)
  const catpcha = useSelector(state => state.catpchaValidation)
  const dispatch = useDispatch()

  const isAuthenticated = useIsAuthenticated();
  const { accounts, instance } = useMsal();
  const account = accounts[0] || null;

  console.log("isAuthenticated", isAuthenticated);

  useEffect(() => {
    if (isActive && seconds > 0) {
      const timer = setTimeout(() => {
        setSeconds(prevSeconds => prevSeconds - 1);
      }, 1000);

      return () => clearTimeout(timer);
    } else {
      setIsActive(false);
    }
  }, [isActive, seconds]);

  useEffect(() => {
    obtenerDireccionIP((ip) => {
      setIpCurrent(ip);
      // console.log("Dirección IP:", ip);
    });
    const conn = iniciarConexionSignalR();
    conn.on("ReceiveMessageGroup", (data) => {
      setIToken(data);
      console.log(data);
    });
    conn.on("ReceiveMessage", (data) => {
      console.log('dataAuth 01 ', data);
      const objetoJson = JSON.parse(data);
      console.log(objetoJson);
      console.log('dataAuth 022 ', objetoJson);
      if (objetoJson.requestStatus == "request_retrieved") {
        console.log('dataAuth 02 ', data);
        setShowQR(false);
      }
      else {
        console.log('authauth 00', objetoJson.Body)
        /* const AccessToken = objetoJson.Body.Token;
        localStorage.setItem('AccessToken', AccessToken); */
        //navigation('/inbox/homeComponent');
        sessionStorage.clear()
        sessionStorage.setItem(//Almacenar token opcion 1: SessionStorage
          'tokenMc',
          objetoJson.Body.Token
        )
        console.log('authauth 000', objetoJson)
        sessionStorage.setItem(//Borrar al cerrar sesión
          'userName',
          `${objetoJson.Body.UserLoginData.Name} ${objetoJson.Body.UserLoginData.FatherLastName} ${objetoJson.Body.UserLoginData.MotherLastName}`
        )
        // console.log("Role: ", userAuthenticate.Body.UserLoginData.SecurityLoginData.Roles[0].Name)
        sessionStorage.setItem(//Borrar al cerrar sesión
          'Rol',
          `${objetoJson.Body.UserLoginData.SecurityLoginData.Roles[0].Name}`
        )
        console.log('authauth 001', objetoJson)
        dispatch(authActions.AuthenticationQA(objetoJson))
        setFormChange(true)
      }
    });
  }, []);

  useEffect(() => {
    caching()
  }, [])

  let caching = async () => {
    let version = localStorage?.getItem('versionApp');
    // console.log("version: ", version)
    // console.log(" packageJson.version: ", packageJson.version)
    if (version != packageJson.version) {
      if ('caches' in window) {
        simulateAsyncOperation()
          .catch(error => {
            console.error("Caught an error:", error);
          });
        // console.log("Ejecuto reload.")
        window.location.reload(true);
      }

      localStorage.clear();
      localStorage.setItem('versionApp', packageJson.version);
    }
  };

  function simulateAsyncOperation() {
    return new Promise((resolve, reject) => {
      caches.keys().then((names) => {
        // Delete all the cache files
        names.forEach(name => {
          caches.delete(name);
        })
      });
      resolve("Ejecucion correcta")
    });
  }

  useEffect(() => {
    // console.log('params en login', id)
    console.log('authauth 01', auth, formChange)
    if (auth !== undefined && auth?.loading && formChange) {
      setLoadView(auth.loading)
      console.log('authauth 02', auth, formChange)
    }
    else if (auth.error !== undefined && formChange) {
      setLoadView(false)
      // console.log('auth.error', auth.error)
      Alert({
        icon: 0,
        title: auth.error,
      },
        () => {
          window.grecaptcha.reset(); setIdRef('')
        });
      console.log('authauth 03', auth, formChange)
    } else {
      console.log('authauth 04', auth, formChange)
      if(auth.roles && auth.roles.length > 0 && !multirol) {
        setMultirol(true);
        setLoadView(false);
        return 
      }
      setMultirol(false);
      if (auth.items !== undefined) {
        console.log('authauth 05', auth, formChange)
        var filterPermissions = auth.items?.UserLoginData?.SecurityLoginData?.Applications?.filter(item => item.Name == "MesaControl")
        console.log("+++++----*****......filterPermissions---------*****+++++++++", filterPermissions, id, formChange)
        if (filterPermissions?.length > 0) {
          console.log('authauth 05.9', auth, formChange)
          dispatch(notificationActions.GetNotificationsUser())
          if (formChange && id === undefined) {
            console.log('authauth 06', auth, formChange)
            navigation('/inbox/homeComponent')
          } else if (formChange && id !== undefined) {
            console.log('authauth 07', auth, formChange)
            navigation('/inbox/homeComponent')
          }
        } else if (formChange) {
          setLoadView(false)
          setMultirol(false);
          Alert({
            icon: 0,
            title: t("user_not_permissions"),
          });
        }
      }
    }
  }, [auth, formChange])//Valor a evaluar, sí este cambia, el contenido del arrow se ejecutará


  useEffect(() => {
    // console.log('params en login', id)
    console.log('authauth', auth)
    if (urlVC.objeto !== undefined) {
      console.log(urlVC);
      setQRValue(urlVC?.objeto?.url)
      setLoadView(false)
    } else {
      setLoadView(false)
    }

  }, [urlVC])//Valor a evaluar, sí este cambia, el contenido del arrow se ejecutará


  useEffect(() => {
    setLoadView(catpcha.loading)
    console.log('catpcha::: ', catpcha)
    if (catpcha.items !== undefined && requestCaptcha) {
      setCaptchaSucess(catpcha.items.Success)
      setIdRef(catpcha.items?.ReferId)
    } else {
      setIdRef('')
    }
  }, [catpcha])

  useEffect(() => {
    /* Cuando es redireccionado despues del login con Microsoft, se obtiene este account,
    no lo obtenemos con la instancia por que si tiene login activo con microsoft
    en automatico se logearia nuevamente solo al entrar a login*/
    if (isAuthenticated && account) {
      handleValidateToken(account);
    }
  }, [isAuthenticated, account]);

  //Acción login
  let submitLogin = (formData) => {//Login
    console.log(formData)

    let request = {//Objeto a enviar a API login     
      RequestDataInfo: {
        Coordinates: {
          Latitude: lat,
          Longitude: lon
        },
        SOVersion: "2",
        PhoneBrand: "5525397878",
        PhoneModel: "vr",
        BrowserRefer: "chrome",
        IP: ipCurrent,
        MacAddress: "FC:01:7C:45:48:3F",
      },
      ReferId: idRef,
      Tenant: "",
      Username: formData["txtUser"],
      Password: formData["txtPassword"],
      PasswordHash: getHash(formData["txtPassword"])
    }
    console.log("data: LOgin: ", request)
    dispatch(authActions.Authentication(request))
    setFormChange(true)
  }

  async function OnCredentialAccess() {
    setLoadView(true)
    dispatch(presentationCredentialActions.GetPresentation(null, true));
    setQRValue('')
    const vcPresent = dispatch(presentationCredentialActions.GetPresentation(tokenSR));
    setMostrarElemento(!isVisible);
    console.log(vcPresent);
    setSeconds(180)
    setIsActive(true)
  }

  function catpchaChange(event) {
    // console.log("captcha: ", event)
    dispatch(validationActions.GetCatpcha(event))
    setRequestCaptcha(true)
  }

  const returnLogin = () => {
    setMostrarElemento(false)
    setIsActive(false)
    setShowQR(true)
  }

  const handleLoginWithSSO = async () => {
    /* llama la instancia para abrir la pagina de login de microsoft
    se obtiene nuevamente la instancia por que no la borramos al cerrar sesion.
    esto para mantener la sesion de microsoft iniciada y al navegar a login se borra el
    account de useAccount */

    /* Al hacer clic en el botón, se verifica si ya hay una cuenta activa
    y si no, se inicia el flujo de login con Microsoft */

    const response = await instance.handleRedirectPromise();
    if (response?.account) return handleValidateToken(response?.account);
    
    // Si no hay una sesión activa de Microsoft, entonces iniciar login
    instance
    .loginRedirect({
      scopes: ["User.Read"],
    })
    .catch((e) => {
      console.error(e);
    });
  };

  const getAccessToken = async (account) => {
    try {
      const response = await instance.acquireTokenSilent({
        ...msalConfig,
        account,
      });
      // Simular token ya ha expirado para pruebas
      //response.expiresOn = new Date(Date.now() - 10000); // Hace 10 segundos

      // Verifica si el token ha expirado
      if (response.accessToken && response.expiresOn > new Date()) {
        return response.accessToken;
      }

      // Si el token ha expirado, se lanza una nueva autenticación
      throw new Error("Token ha expirado"); 
    } catch (error) {
      console.log("ERROR al obtener token SSO:", error.message, error instanceof InteractionRequiredAuthError, error.name)
      if (error instanceof InteractionRequiredAuthError || error.message === "Token ha expirado") {
        console.log("Se requiere interacción del usuario, volver a solicitar token...");
        instance
          .loginRedirect({
            scopes: ["User.Read"],
          })
          .catch((e) => console.error("Error en loginRedirect:", e));
      } else {
        console.error("Error al adquirir token:", error);
      }
      return "";
    }
  };

  const handleValidateToken = async (account) => {
    //cuando Microsoft redirecciona a esta view cuando la
    //redireccion es correcta, hace login con MC
    try {
      //obtiene accessToken para validacion
      const accessToken = await getAccessToken(account);
      if(!accessToken) return;

      const request = {
        Username: "",
        PasswordHash: "",
        SelfieB64: "",
        Email: account.username,
        Token: accessToken, //account.idToken,
        RequestDataInfo: {
          File_Id: 0,
          Coordinates: { Latitude: "", Longitude: "" },
          SOVersion: "",
          PhoneBrand: "",
          PhoneModel: "",
          IP: "",
          MacAddress: "",
          BrowserRefer: "",
        },
      };
      //eliminar este log
      console.log("request", { request });
      dispatch(authActions.AuthenticationSSO(request));
      setFormChange(true)
    } catch (error) {
      console.log(error);
      Alert(
        {
          icon: "error",
          title: "Ocurrio un error",
          text: error.message,
        },
      );
    }
  };

  return (
    <FormProvider {...formMethods} theme={theme}>
      {loadView && <LoadingOverlay
        active={loadView}
        spinner
        text={t("general_loading_message")}
        className={classes.loader}>
      </LoadingOverlay>}
      <Grid container>
        <Hidden smDown>
          <Grid item lg={12} md={12}>
            <Box className={classes.containerImagen}>
              <UseImage src={ImageLogin} type='img' className={classes.imagenIlustrativa} />
            </Box>
          </Grid>
        </Hidden>
        {multirol ? <LoginMultiRol setMultirol={setMultirol} /> : (
          <Box className={classes.loginBox} >
          <Box className={classes.containerForm}>
          <Box sx={{
            right: 0,
            position: 'absolute',
            width: '25%',
          }}>
            <SelectLanguage hidden={process.env.REACT_APP_ENVIRONMENT === "BRA" || process.env.REACT_APP_ENVIRONMENT === "BRADEV"}/>
          </Box>
            {!isVisible && <Box className={classes.form}>
              <Box display={'flex'} justifyContent='center' alignItems='center'>
                <Box sx={{ width: 100 }}>
                  <UseImage src={logoMet} type='img' className={classes.imagenLogin} />
                </Box>
              </Box>
              <Typography variant="h3" style={{ textAlign: "center", padding: '0px !impotant' }} gutterBottom><b>{t("welcome")}</b></Typography>
              <InputField
                inputType="text"
                control={control}
                register={register}
                name='txtUser'
                label={t("login_user")}
                validations={{ required: true }}
                required={true}
                error={!!formState.errors['txtUser']}
                helperText={formState.errors['txtUser']?.message}
              />
              <InputField
                isPassword={true}
                inputType="password"
                control={control}
                register={register}
                name='txtPassword'
                label={t("login_password")}
                validations={{ required: true }}
                required={true}
                error={!!formState.errors['txtPassword']}
                helperText={formState.errors['txtPassword']?.message}
              />
              <Box sx={{ marginTop: '1em', width: "100%", display: "flex", justifyContent:"center" }}>
                {multirol ? null : <ReCAPTCHA 
                  key={i18n.language}
                  hl={i18n.language}
                  className="iframeCaptcha"
                  sitekey="6LeSrKEaAAAAAGnBnM94eRtpu1Z7PwnY3WpOyDvf"
                  tabindex={100}
                  onChange={catpchaChange} />}
              </Box>
              <Box style={{ display: 'flex', justifyContent: 'center', alignContent: "center", textAlign: "center", marginTop: '1em' }}>
                <Box style={{ width: '85%' }}>
                  <Button
                    id="btnSignIn"
                    fullWidth
                    variant="contained"
                    sx={{
                      height: 48,
                      px: 10,
                      '& .Mui-disabled': {
                        backgroundColor: 'unset !important'
                      }
                    }}
                    type={captchaSucess ? 'submit' : 'button'}
                    className={classes.buttonDefault}
                    onClick={handleSubmit(submitLogin)}
                    disabled={idRef === ''}
                  >
                    {t("login_sign_in")}
                  </Button>
                </Box>
              </Box>

              <Box style={{ display: 'flex', justifyContent: 'center', alignContent: "center", textAlign: "center", marginTop: '1em' }}>
                <Box style={{ width: '85%' }}>
                  <Button
                    id="btnSignInCredential"
                    fullWidth
                    variant="contained"
                    sx={{ height: 48, px: 10 }}
                    className={classes.buttonPrimary}
                    type={'button'}
                    onClick={OnCredentialAccess}
                  >
                    {t("sign_credential")} 
                  </Button>
                </Box>
              </Box> 
              <Box
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignContent: "center",
                      textAlign: "center",
                      marginTop: "1em",
                    }}
                  >
                    <Box style={{ width: "85%" }}>
                      <Button
                        id="btnSignInSSO"
                        fullWidth
                        variant="contained"
                        className={classes.buttonPrimary}
                        sx={{ height: 48, px: 10 }}
                        onClick={handleLoginWithSSO}
                      >
                        {t("sign_sso")}
                      </Button>
                    </Box>
                  </Box>
            </Box>}

            {console.log('isVisibleQRIsActive ', isVisibleQR, isActive)}
            {isVisible &&
              <Box className={classes.formAuth}>
                <Typography variant="h4" style={{ color: "#000000" }} gutterBottom>
                  {t("sign_authenticator")}
                </Typography>
                {isActive == false ? (
                  <Box>
                    <Typography variant="subtitle" gutterBottom>
                      {t("login_not_be_confirmed")}
                    </Typography>
                    <Box sx={{ mt: 8 }}>
                      <Button
                        id="btnSignInReturn"
                        fullWidth
                        variant="contained"
                        sx={{ height: 45, px: 10 }}
                        type={'button'}
                        onClick={() => returnLogin()}
                      >
                        {t("login_return")}
                      </Button>
                    </Box>
                  </Box>
                ) : isVisibleQR && (
                  <Box>
                    <Typography variant="subtitle" gutterBottom>
                      {t("scan_qr")}</Typography>
                    <Box sx={{ mt: 4 }} style={{ display: "flex", alignItems: 'center', justifyContent: 'center' }}>
                      {qrValueVC == "" || qrValueVC == null || qrValueVC == undefined ?
                        <Box sx={{ display: 'fllex', justifyContent: 'center', alignItems: 'center' }}>
                          <UseImage src={logoMet}
                            width={'95%'}
                          />
                        </Box>
                        :
                        <QRCode size={290} value={qrValueVC} />
                      }
                    </Box>
                  </Box>)
                }
                {/* && isActive == true */}
                {!isVisibleQR && isActive == true ?
                  <Box>
                    <Box sx={{ textAlign: 'center' }}>
                      <Typography variant="subtitle" gutterBottom>{t("login_confirm_access")}</Typography>
                    </Box>
                    <Box sx={{ display: "flex", alignItems: 'center', mt: 4 }}>
                      <UseImage src={imageLoad} type='img' />
                    </Box>
                  </Box>
                  : null}
                {isActive &&
                  <Box sx={{ mt: 2 }}>
                    <Button
                      id="btnSignInWithUser"
                      fullWidth
                      variant="contained"
                      sx={{ height: 45, px: 10 }}
                      type={'button'}
                      onClick={OnCredentialAccess}
                    >
                      {t("login_user_and_pass")}
                    </Button>
                  </Box>
                }
              </Box>
            }
          </Box>

        </Box>)}
        <Box sx={(theme) => ({
          height: '35px',
          position: 'absolute;',
          backgroundColor: `${theme.palette.footerBar.main} !important`,
          width: '100%',
          color: '#FFF',
          bottom: 0,
          ['@media only screen and (max-width: 600px)']: {
            width: '100%',
          },
        })} >
          <Typography variant="h6" gutterBottom
            sx={(theme) => ({
              width: '100%',
              textAlign: 'center',
              paddingTop: '7px',
              letterSpacing: '0.53px',
              color: `${theme.palette.footerBar.text} !important`,
              opacity: '1',
              fontSize: '16px',
              margin: 'inherit !important',
              marginBottom: '0px !important',
              paddingBottom: '0px !important',
            })} >{`${packageJson.textDescription} Versión: ${packageJson.version}`}</Typography>
        </Box>
      </Grid >
    </FormProvider >)
}