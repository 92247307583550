import React, { useState } from "react";
import { useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import logoMet from "../../assets/MetLifeLogo.svg";
import { Alert, UseImage } from "../../components/";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "../../store/actions";
import { useNavigate } from "react-router-dom";
import { authRequests } from "../../services";
import { AccountCircle } from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
  imagenLogin: {
    height: "32px",
    backgroundRepeat: "none",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  loginBox: {
    top: 0,
    bottom: 0,
    margin: "auto 0",
    width: "35%",
    maxHeight: "60vh",
    right: "2%",
    overflow: "auto",
    position: "absolute",
    background: "#FFFFFF",
    boxShadow: "0px 4px 100px 0px rgba(138, 138, 138, 0.25)",
    borderRadius: 63,
    padding: "25px 35px",
  },
  buttonsContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
  },
}));

export default function LoginMultiRol({ setMultirol }) {
  const theme = useTheme();
  const { t } = useTranslation();
  const classes = useStyles(theme);
  const auth = useSelector((state) => state.Authentication);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [selectedIndex, setSelectedIndex] = useState({
    index: null,
    data: null,
  });

  if (!auth.roles || !auth.roles.length) return;

  const handleListItemClick = (data, index) => {
    setSelectedIndex({ index, data });
  };

  const handleSignInOnTenant = async () => {
    try {
      if (!selectedIndex.data) return;
      //crear input de tokens a revocar
      const TokensDisable = auth.roles
        .filter((rol) => rol.Token !== selectedIndex.data.Token)
        .map((rol) => ({
          Token: rol.Token,
          UserId: rol.UserLoginData.Id,
        }));

      await authRequests.RevokeUserTokens({ TokensDisable });

      dispatch(authActions.AuthenticationTenant(selectedIndex.data));

      const { Token, UserLoginData } = selectedIndex.data;
      // setear data en localStorage
      sessionStorage.clear();
      sessionStorage.setItem("tokenMc", Token);
      sessionStorage.setItem(
        "userName",
        `${UserLoginData.Name} ${UserLoginData.FatherLastName} ${UserLoginData.MotherLastName}`
      );
      sessionStorage.setItem(
        "Rol",
        `${UserLoginData.SecurityLoginData.Roles[0]?.Name}`
      );
    } catch (error) {
      console.log(error.response);
      Alert(
        {
          icon: 0,
          title: error?.response?.status || "",
          text: error?.response?.data
            ? `${error?.response?.data?.Messages}. TransactionId: ${error?.response?.data?.TransactionId}`
            : "",
        },
        () => handleClearData()
      );
    }
  };

  const handleClearData = () => {
    localStorage.clear();
    sessionStorage.clear();
    setMultirol(false);
    navigate("/auth");
  };

  return (
    <Box className={classes.loginBox}>
      <Box display="flex" gap={2} justifyContent="center" alignItems="center">
        <UseImage src={logoMet} type="img" className={classes.imagenLogin} />
        <Typography variant="h3">
          <b>{t("login_sign_in")}</b>
        </Typography>
      </Box>
      <br />
      <Typography align="center" sx={{ fontWeight: "100" }}>
        {t("multiple_users_detected")}
      </Typography>
      <Box my={3}>
        <List disablePadding>
          {auth.roles.map((res, index) => {
            return (
              <ListItemButton
                key={index}
                selected={selectedIndex.index === index}
                onClick={() => handleListItemClick(res, index)}
                sx={{ borderBottom: "1px solid", borderColor: "divider" }}
              >
                <ListItemIcon>
                  <AccountCircle fontSize="large" />
                </ListItemIcon>
                <ListItemText
                  primary={res.UserLoginData.FullName} //{res.WorkFlowData.WorkFlowName}
                  secondary={`${res.UserLoginData.Username}`} //{res.UserLoginData.SecurityLoginData.Roles[0].Name}
                />
              </ListItemButton>
            );
          })}
        </List>
      </Box>
      <Box className={classes.buttonsContainer}>
        <Button
          id="btnSignInMulti"
          fullWidth
          variant="contained"
          sx={{ height: 48 }}
          disabled={!selectedIndex.data}
          onClick={handleSignInOnTenant}
        >
          {t("login_sign_in")}
        </Button>
        <Button
          id="btnSignOutMulti"
          fullWidth
          variant="outlined"
          sx={{ height: 48 }}
          onClick={handleClearData}
        >
          {t("login_return")}
        </Button>
      </Box>
    </Box>
  );
}
