import React, { useEffect, useState } from 'react'
import { InputLabel, Select, MenuItem, FormControl } from "@mui/material";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setDefaultLanguageAction } from '../store/actions/GenericActions';

export const SelectLanguage = ({hidden = false}) => {
  const { i18n, t } = useTranslation();
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.SetDefualtLanguage?.lang);
  const defaultEnv =
    sessionStorage.getItem("lang") || process.env.REACT_APP_LANGUAGE || "es";
  const defaultLang = hidden
    ? process.env.REACT_APP_LANGUAGE || "es"
    : lang
    ? lang
    : defaultEnv;

  const LANGUAGES = [
    { label: 'Español', code: 'es' },
    { label: 'Português', code: 'pt' },
  ]

  /* useEffect(() => {
    i18n.changeLanguage('es');
  }, []) */

  console.log("LANGG", lang, sessionStorage.getItem("lang"))

  useEffect(() => {
    dispatch(setDefaultLanguageAction(defaultLang))
    i18n.changeLanguage(defaultLang);
    sessionStorage.setItem("lang", defaultLang)
  }, [])

  const onChangeLang = (e) => {
    const lang_code = e.target.value
    console.log("lang_code: ", lang_code)
    dispatch(setDefaultLanguageAction(lang_code))
    i18n.changeLanguage(lang_code);
    sessionStorage.setItem("lang", lang_code)
  }

  if(hidden) return <div />

  return (
    <FormControl fullWidth>
      <InputLabel id="selectLanguage-label">{t('general_language')}</InputLabel>
      <Select 
        labelId="selectLanguage-label"
        id="selectLanguage"
        label={t('general_language')}
        value={i18n.language}
        onChange={onChangeLang}
      >
        {LANGUAGES.map(({ code, label }) => (
          <MenuItem value={code}>{label}</MenuItem>
        ))}
      </Select>
    </FormControl>
  )
};