/* eslint-disable default-case */
import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Icon } from "@iconify/react";
import { Avatar, Box, Hidden, Typography, useTheme } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { fileActions } from "../../store/actions";
import { paths, routes } from "../../utils";
import { constPathRoot } from "../../utils/PathPublish";
import { useStyles } from "./HeaderStyles";
import Colombia from "../../assets/icons/Colombia.png";
import Argentina from "../../assets/icons/Argentina.png";
import Chile from "../../assets/icons/Chile.png";
import Uruguay from "../../assets/icons/Uruguay.png";
import Mexico from "../../assets/icons/Mexico.png";
import Brazil from "../../assets/icons/Brazil.png";
import { useTranslation } from "react-i18next";
//import { useIsAuthenticated, useMsal } from '@azure/msal-react';

export function Header() {
  const location = useLocation();
  const theme = useTheme();
  const localStyles = useStyles(theme);
  const dispatch = useDispatch();
  const { id } = useParams();
  const [product, setproduct] = useState("");
  const [user, setUser] = useState("");
  const [name, setName] = useState("");
  const [rolUser, setRolUser] = useState("");
  const [initial, setInitial] = useState("");
  const navigate = useNavigate();

  const { t } = useTranslation();

  const infoFile = useSelector((state) => state.FileInfo);
  const auth = useSelector((state) => state.Authentication);
  const { DataReport } = location.state || {};
  const workFlowInstanceId = sessionStorage.getItem("WorkFlowInstanceId");

  //const { instance  } = useMsal();
  //const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    // console.log('Inicitial---- headers', id)
    // console.log("Initial---- window.sessionStorage", sessionStorage)
    dispatch(
      fileActions.GetFileInfo({
        FileId: id,
        WorkFlowInstanceId: workFlowInstanceId,
      })
    );

    let userVal = sessionStorage.getItem("userName");
    if (userVal !== undefined && userVal !== null && userVal !== "") {
      // console.log("Entra en validacion------ ", userVal)
      let nameVal = userVal; //user.split(" ")[0]
      let rolUserVal = sessionStorage.getItem("Rol");
      let initialVal = userVal?.split("")[0];

      setUser(userVal);
      setName(nameVal);
      setRolUser(rolUserVal);
      setInitial(initialVal);
    } else {
      console.log(
        "Entra en constantes de header------ ",
        user,
        name,
        rolUser,
        initial
      );
    }
  }, []);

  useEffect(() => {
    if (infoFile.items !== undefined) {
      setproduct(infoFile.items.Product);
    }
  }, [infoFile]);

  useEffect(() => {
    if (auth.items !== undefined) {
      console.log("Datos Login:----------------------------------------------- ", auth.items)
      let userVal = `${auth.items.UserLoginData.Name} ${
        auth.items.UserLoginData.FatherLastName
      } ${auth.items.UserLoginData.MotherLastName ?? ""}`;
      let nameVal = userVal; //user.split(" ")[0]
      let rolUserVal = `${auth.items.UserLoginData.SecurityLoginData.Roles[0].Name}`;
      let initialVal = userVal?.split("")[0];
      // console.log("Datos Login:---------------setters-------------------------- ", userVal, nameVal, rolUserVal, initialVal)
      console.log("userVal", auth, userVal);
      setUser(userVal);
      setName(nameVal);
      setRolUser(rolUserVal);
      setInitial(initialVal);
      // sessionStorage.setItem('userName', userVal)
      // sessionStorage.setItem('Rol', rolUserVal)
    } else if (auth.error !== undefined) {
      // setLoadView(false)
      // alert(auth.error)
      console.log("(auth.error)-----------: ", auth.error);
    }
  }, [auth]);

  let path = window.location.pathname;

  // console.log('pathpath', path)

  // AQUI DEBES DE CONDICIONAR QUE TIENES EN LA URL PARA PODER DAR EL TITULO DE LA MISMA
  const setSectionName = () => {
    console.log("path: ", path);
    switch (path) {
      case `${constPathRoot.pathRoot}/inbox/dashboards`:
        return t("main_dashboard");
      case `${constPathRoot.pathRoot}/inbox/search`:
        return t("search");
      case `${constPathRoot.pathRoot}/inbox/new-file`:
        return t("main_candidates");
      case `${constPathRoot.pathRoot}/inbox/homeComponent`:
        return t("main_assignments");
      case `${constPathRoot.pathRoot}/inbox/homeComponent/single`:
        return "Asignaciones Individuales";
      case `${constPathRoot.pathRoot}/inbox/homeComponent/masive`:
        return "Asignaciones Masivas";
      case `${constPathRoot.pathRoot}/inbox/busqueda/:id`:
        return t("main_assignments");
      case `${constPathRoot.pathRoot}/inbox/bulkUsers`:
        return t("main_candidates_massive");
      case `${constPathRoot.pathRoot}/inbox/bulkDocuments`:
        return t("main_documents");
      case `${constPathRoot.pathRoot}/inbox/uploadDocumentsMasive`:
        return t("main_documents");
      case `${constPathRoot.pathRoot}/inbox/UserManagement`:
        return t("main_admin_users");
      case `${constPathRoot.pathRoot}/inbox/BulkMetadata`:
        return t("main_upload_massive_metadata");
      case `${constPathRoot.pathRoot}/inbox/bulkMetadataUpload`:
        return t("main_upload_massive_metadata");
      case `${constPathRoot.pathRoot}/inbox/Charts`:
        return t("main_dashboard");
      case `${constPathRoot.pathRoot}/inbox/inboxReportsFilter`:
        return t("main_reports");
      case `${constPathRoot.pathRoot}/inbox/reportsData`:
        return `${DataReport.Description}`;
    }
  };

  const PaisSeleted = (data) => {
    switch (data) {
      case "Contratación Colombia Estándar":
        return Colombia;
      case "Contratación Colombia Masivo":
        return Colombia;
      case "Contratación Uruguay":
        return Uruguay;
      case "Contratación Argentina":
        return Argentina;
      case "Contratación México":
        return Mexico;
      case "Contratación Expand México":
        return Mexico;
      case "Contratación México WSM":
        return Mexico;
      case "Contratación México Individual Privado":
        return Mexico;
      case "Contratación ML Chile Masivo":
        return Chile;
      case "Contratación ML Chile Estándar":
        return Chile;
      case "Contratación ML Chile Seguros":
        return Chile;
      case "Contratación ML Chile Provida":
        return Chile;
      case "Contratação no Brasil":
        return Brazil;
    }
  };

  const Logout = (e) => {
    // si esta auth con sso, evita redireccion al logindirectamente y se hace mediante la instancia
    /* Comentado por si se necesita esta accion */
    //if(isAuthenticated) e.preventDefault();
    console.log("CIERRASESION");
    localStorage.clear();
    sessionStorage.clear();
    sessionStorage.removeItem("tokenMc");
    localStorage.removeItem("versionApp");

    //si esta auth con sso hace logout con microsoft y redirecciona a login
    /* Comentado por si se necesita esta accion */
    /* if(isAuthenticated){
            instance.logoutRedirect().catch(e => {
                console.error(e);
            });
        } */
  };

  console.log(path.includes(paths.folio));
  return (
    <Box
      style={{
        display: "flex",
        alignItems: "center",
        margin: ".7em 1.5em 0 1.5em",
      }}
    >
      <Box display="flex" alignItems={"center"} flexGrow={1}>
        {path.includes(paths.folio) || path.includes(paths.folioSearch) ? (
          <Box style={{ display: "flex", alignItems: "center" }}>
            <ArrowBackIcon
              style={{
                color: theme.palette.userStyle.textDetails,
                marginRight: ".6em",
              }}
              onClick={() => {
                if (path.includes(paths.folio)) {
                  return navigate(routes.home);
                } else if (path.includes(paths.folioSearch)) {
                  return navigate(routes.home);
                  // return navigate(routes.search)
                }
              }}
            />
            <Typography
              style={{
                color: theme.palette.userStyle.textDetails,
                fontSize: "30px",
                fontWeight: 550,
              }}
            >
              {t("request_data_header")}
            </Typography>
          </Box>
        ) : (
          ""
        )}
        <Typography
          style={{
            color: theme.palette.userStyle.textDetails,
            fontSize: "30px",
            fontWeight: 550,
          }}
        >
          {setSectionName()}
        </Typography>
      </Box>
      <Hidden smDown>
        <Box style={{ display: "flex", alignItems: "center" }}>
          {/* <NotificationsView /> */}
          <Box>
            <Avatar
              style={{
                color: "primary.mainLyrics",
                backgroundColor: "primary.main",
                fontWeight: 600,
                width: 75,
                height: 75,
                fontSize: 40,
                mt: 0.7,
              }}
              alt={sessionStorage.userName}
              src={PaisSeleted(auth?.items?.WorkFlowData?.WorkFlowName)}
            />
          </Box>
          <Box style={{ marginLeft: ".5em" }}>
            <Typography style={{ color: theme.palette.userStyle.textDetails }}>
              {name}
            </Typography>
            <Typography style={{ color: theme.palette.userStyle.textDetails }}>
              {rolUser}
            </Typography>
            <Typography
              style={{ color: theme.palette.userStyle.textDetails }}
              variant="caption"
            >
              <Link
                to="/auth/login"
                style={{ color: theme.palette.userStyle.textDetails }}
                onClick={() => {
                  Logout();
                }}
              >
                {t("log_out")}
              </Link>
            </Typography>
          </Box>
        </Box>
      </Hidden>
    </Box>
  );
}

{
  /* <div className={localStyles.headerName} >
                {path.includes(paths.folio) || path.includes(paths.folioSearch) ?
                    <ArrowBackIcon
                        style={{ color: "#a0a1a2" }}
                        onClick={() => {
                            if (path.includes(paths.folio)) {
                                return navigate(routes.home)
                            } else if (path.includes(paths.folioSearch)) {
                                return navigate(routes.search)
                            }
                        }} />
                    : ""}
                <Typography variant="h6" className={localStyles.h6}>{setSectionName()}</Typography>
            </div>
            <div className={localStyles.generalSection}>
                <div className={localStyles.sectioUser}>
                    <Typography className={localStyles.p}>{name}</Typography>
                    <Typography className={localStyles.text}>{rolUser}</Typography>
                    <Typography className={localStyles.text}>
                        <Link className={localStyles.link} onClick={Logout}>
                            Cerrar Sesión
                        </Link>
                    </Typography>
                </div>
                <div className={localStyles.userIcon}>
                    <p className={localStyles.capital}>{initial}</p>
                </div>
            </div>  */
}
